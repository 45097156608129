import { get, post, instance1 } from '../utils/request'
import qs from 'qs'
export function getList() {
  return get('/webim/Common/init', {})
}

export function getMsg(data) {
  return post('/webim/Friends_Message/add', qs.stringify(data))
}

// 查询好友
export function applyFirend(data) {
  return post('/webim/Message_Box/applyFirend', qs.stringify(data))
}
// 添加好友
export function findFriend(data) {
  return post('/webim/Friends/find', qs.stringify(data))
}
// 在线状态
export function getOnline(data) {
  return post('/webim/Friends/onLine', qs.stringify(data))
}
// 离线状态
export function getOffline(data) {
  return post('/webim/Friends_Message/offline', qs.stringify(data))
}

export function findFriendHistory(data) {
  return post('/webim/Friends_Message/messageHistory', qs.stringify(data))
}
// 同意好友
export function agreeFriend(data) {
  return post('/webim/Message_Box/agreeFriend', qs.stringify(data))
}

// 家庭联系人
export function getFamily(id) {
  return get('/index/family/family_list?user_id=' + id)
}

// 家庭联系人报告单
export function getFamilyOrder(patientId) {
  return get('/index/family/family_single?patient_id=' + patientId)
}

// 查询患者文档列表
export function getDocument(MES0051_XML) {
  return get(
    'https://wx.weilaihos.com/app/main?hi=24DL012Z4QF9&action=MES0101&message=' +
      MES0051_XML
  )
}

// 家庭联系人报告单详情
export function getFamilyOrderDetail(MES0051_XML) {
  return get(
    'https://wx.weilaihos.com/app/main?hi=24DL012Z4QF9&action=MES0051&message=' +
      MES0051_XML
  )
}

// 家庭联系人查看报告
export function getFamilyOrderDetailSelect(MES0101_XML) {
  return get(
    'https://wx.weilaihos.com/app/main?hi=24DL012Z4QF9&action=MES0101&message=' +
      MES0101_XML
  )
}

export function getDocUpload(data) {
  return post(
    'https://wx.weilaihos.com/api/index/doctor_img_update',
    qs.stringify(data)
  )
}
// 本人就诊记录
export function getFamilyOrderJiuzhen(id, name) {
  return get(
    'https://wx.weilaihos.com/app/main?hi=24E1HFQXZGIG&user_id=' +
      id +
      '&mobile_num=' +
      name
  )
}
// 就诊记录
export function getOrderDetails(MES0051) {
  return get(
    'https://wx.weilaihos.com/app/main?hi=24DL012Z4QF9&action=MES0051&message=' +
      MES0051
  )
}

// 家庭联系人查看报告详情页
export function getFamilyOrderDetailSelectXiangQing(action, message) {
  return get(
    'https://wx.weilaihos.com/app/main?hi=24DL012Z4QF9&action=' +
      action +
      '&message=' +
      message
  )
}
// 积分流水
export function getScore() {
  return get('/index/Score/index', {})
}
// 账户流水
export function getAccount() {
  return get('/index/Wallet/index', {})
}
// 拒绝好友
export function refuseFriend(data) {
  return post('/webim/Message_Box/refuseFriend', qs.stringify(data))
}
// 问诊订单
export function getOrder(data) {
  return post('/addons/ask/Order/getOrderList', qs.stringify(data))
}
// 退号

export function getOrderExit(AdmNo) {
  return get(
    'https://wx.weilaihos.com/app/main?hi=24DL012Z4QF9&action=MES0052&message=' +
      AdmNo
  )
}
// 查看被邀请人列表

export function getInviteList() {
  return get('/index/index/subordinate', {})
}
// 医院列表获取

export function getHospitlList() {
  return post('/api/Index/hospital_list')
}
// 医院列表详细获取

export function getHospitlListDetail(id) {
  return post('/api/index/hospital_detail', qs.stringify(id))
}
// 根据HIS科室名称获取对应的Map科室名称
export function getMap(data) {
  instance1({
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    url: 'https://msg.weilaihos.com/msg-service/map/getMapName',
    data,
    method: 'POST'
  })
}

// 文档调阅
export const report = ({
  MessageID, // 消息ID
  PATPatientID, // 患者主索引
  PAADMVisitNumber, // 就诊号码
  DocumentID, // 文档ID
  DocumentType // 文档类别
  // 服务编码 S0035
}) => {
  const query =
    '<Request>' +
    '<Header>' +
    '<SourceSystem>S0035</SourceSystem> ' +
    '<MessageID>' +
    MessageID +
    '</MessageID> ' +
    '</Header>' +
    '<Body>' +
    '<DocumentSearchRt>' +
    '<PATPatientID>' +
    PATPatientID +
    '</PATPatientID>' +
    '<PAADMVisitNumber>' +
    PAADMVisitNumber +
    '</PAADMVisitNumber>' +
    '<DocumentType>' +
    DocumentType +
    '</DocumentType>' +
    '<DocumentID>' +
    DocumentID +
    '</DocumentID>' +
    '</DocumentSearchRt>' +
    '</Body>' +
    '</Request>'

  return get(
    'https://wx.weilaihos.com/app/main?hi=24DL012Z4QF9&action=MES0102&message=' +
      query
  )
}
