<template>
  <div id="box" style="width:100%;backround:blue;">
    <!-- 头部导航 -->
    <!--   -->
    <van-nav-bar
      class="chat_title"
      :title="toUser.nickname"
      left-text=""
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />
    <!-- 聊天内容 -->
    <div class="message_box">
      <van-row
        type="flex"
        class="message_box_user"
        v-for="(item, index) in list"
        :key="index"
      >
        <van-image
          round
          width="3rem"
          height="3rem"
          :src="item.avatar"
          style="margin-left:1rem;order:1"
        />

        <van-col
          span="18"
          offset="1"
          :class="item.username == nameD ? 'chat_msg_right' : 'chat_msg_left'"
        >
          <p>{{ String(item.content) }}</p>
        </van-col>
      </van-row>
    </div>
    <!-- 发送按钮 -->
    <div class="send_box">
      <!-- <van-icon name="comment-o" class="send_icon" /> -->
      <van-field
        class="send_ipt"
        v-model="txt"
        center
        clearable
        placeholder="输入聊天内容"
      >
        <template #button>
          <van-button type="primary" @click="speak" @keyup.enter="speak"
            >发送</van-button
          >
        </template>
      </van-field>
    </div>
  </div>
</template>
<script>
import { baseUrl, wsUrl } from '../utils/config'
import { getMsg, findFriendHistory } from '../services/home'
// import imgJson from '../utils/img.json'
import { Toast } from 'vant'
import { mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      mineDialog: [],
      list: [],
      listArr: [],
      txt: '',
      history: [],
      nameD: '',
      toUser: '',
      nicked: '',
      Newmessage: {}, // 新消息
      websock: null // 建立的连接
    }
  },
  computed: {
    ...mapGetters(['getUnread'])
  },
  created() {
    localStorage.setItem('toto', this.$route.query.nickname)
    this.toUser = JSON.parse(localStorage.getItem('to'))
    const m = JSON.parse(localStorage.getItem('mine'))
    this.nameD = m.nickname

    const id = JSON.parse(localStorage.getItem('to'))
    const cliId = localStorage.getItem('clientId')
    findFriendHistory({
      client_id: cliId,
      targetid: id.id,
      page: {}
    }).then(res => {
      this.list = res.data.data.list.reverse()
      // console.log(this.list)
      this.list.forEach(e => {
        var obj = e.avatar
        if (obj.indexOf('data:') !== 0) {
          e.avatar = baseUrl + e.avatar
        }
      })
    })
  },
  mounted() {
    this.$store.state.websock.setHook({
      type: 'chatMessage',
      call: e => {
        const mydata = JSON.parse(e.data)
        this.Newmessage = mydata.data
        // 转化时间格式
        const time = this.$moment(parseInt(this.Newmessage.timestamp)).format(
          'hh:mm:ss'
        )
        this.Newmessage.timestamp = time

        this.list.push({
          id: this.Newmessage.id,
          avatar: baseUrl + this.Newmessage.avatar,
          username: this.Newmessage.username,
          content: this.Newmessage.content,
          timestamp: time
        })
        // })
      }
    })

    this.scrollToBottom()
  },
  updated: function() {
    this.scrollToBottom()
  },
  methods: {
    ...mapMutations([
      'addUnred',
      'init',
      'setLocal',
      'outHome',
      'clearUnread',
      'setCurrentID'
    ]),
    scrollToBottom() {
      this.$nextTick(() => {
        var container = this.$el.querySelector('.message_box ')
        window.scrollTo(0, container.scrollHeight)
      })
    },
    onClickLeft() {
      // localStorage.removeItem('msg_un')
      // this.setCurrentID(item.id)
      // this.outHome()
      // this.clearUnread({ id: item.id })
      // this.setLocal()
      const { uid } = this.$route.query
      if (!uid) {
        this.$router.go(-1)
      } else {
        this.$router.go(-3)
      }
    },
    btnDetail() {
      this.$router.push({
        name: 'detail'
      })
    },

    speak() {
      if (this.txt !== '') {
        const m = JSON.parse(localStorage.getItem('mine'))
        const cid = localStorage.getItem('clientId')
        const t = JSON.parse(localStorage.getItem('to'))

        if (m.avatar.indexOf('data:') !== 0) {
          this.list.push({
            username: m.nickname,
            content: this.txt,
            avatar: baseUrl + m.avatar
          })
        }

        const chat = JSON.parse(localStorage.getItem('chat'))
        const timer =
          new Date().getHours() +
          ':' +
          new Date().getMinutes() +
          ':' +
          new Date().getSeconds()
        if (chat == null) {
          if (localStorage.getItem('mineDialog') == null) {
            localStorage.setItem('mineDialog', JSON.stringify(this.mineDialog))
          } else {
            this.listArr = JSON.parse(localStorage.getItem('mineDialog'))
          }
          this.listArr = this.mineDialog
        } else {
          this.listArr = [...chat, ...this.mineDialog, timer]
          this.listArr.sort(function(a, b) {
            return a - b
          })
          localStorage.setItem('listArr', JSON.stringify(this.listArr))
        }
        // const myDate = new Date()
        // const mytime = myDate.toLocaconstimeString()
        // 获取当前时间
        getMsg({
          client_id: cid,
          data: {
            conversation_id: t.conversation_id,
            mine: {
              username: m.username,
              avatar: m.avatar,
              id: m.id,
              mine: true,
              content: this.txt
            },
            to: {
              username: t.username,
              avatar: t.avatar,
              id: t.id,
              type: 'friend',
              content: t.newMsg,
              timestamp: t.updateTime,
              mine: false,
              name: t.username,
              status: t.status,
              historyTime: timer
            }
          }
        })

        this.txt = ''
      } else {
        Toast('发送消息不能为空!')
      }
    },
    initWebSocket() {
      this.websock = new WebSocket(wsUrl)
      this.websock.onopen = this.websocketOnopen
      this.websock.onerror = this.websocketOnerror
      this.websock.onmessage = this.websocketOnmessage
      this.websock.onclose = this.websocketOnclose
    },
    reset() {
      // 重置心跳
      var that = this
      // 清除时间
      clearTimeout(that.timeoutObj)
      clearTimeout(that.serverTimeoutObj)
      // 重启心跳
      that.start()
    },
    start() {
      // 开启心跳
      var self = this
      self.timeoutObj && clearTimeout(self.timeoutObj)
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj)
      self.timeoutObj = setTimeout(function() {
        // 这里发送一个心跳，后端收到后，返回一个心跳消息
        if (self.websock.readyState === 1) {
          // 如果连接正常
          self.websock.send('heartbeat')
        } else {
          // 否则重连
          self.reconnect()
          console.log('心跳错误')
        }
        self.serverTimeoutObj = setTimeout(function() {
          // 超时关闭
          self.websock.close()
        }, self.timeout)
      }, self.timeout)
    },
    websocketOnopen() {
      this.start()
      console.log('ws连接成功!' + new Date().toLocaleString())
    },
    websocketOnerror: function(e) {
      console.log('WebSocket连接发生错误')
      this.reconnect()
    },
    websocketOnmessage: function(e) {
      //  解析对象
      this.mydata = JSON.parse(e.data)
      // console.log(this.mydata)

      switch (this.mydata.type) {
        // 聊天推送
        case 'chatMessage':
          var data = [this.mydata.data]
          data.forEach(e => {
            var obj = e.avatar
            if (obj.indexOf('data:') !== 0) {
              e.avatar = baseUrl + e.avatar
            }
            this.list.push({
              id: e.id,
              avatar: baseUrl + e.avatar,
              username: e.username,
              content: e.content
            })
            this.chat = localStorage.getItem('chat')
              ? JSON.parse(localStorage.getItem('chat'))
              : []

            if (this.chat.length < 100) {
              if (obj.indexOf('data:') !== 0) {
                e.avatar = baseUrl + e.avatar
                this.chat.push({
                  id: e.id,
                  avatar: baseUrl + e.avatar,
                  username: e.username,
                  content: e.content
                })
              }
            }

            localStorage.setItem('chat', JSON.stringify(this.chat))
          })
          break
        // 同意添加好友
        case 'applyFriend':
          console.log('applyFriend' + this.mydata)
          break
        case 'online':
          console.log('online' + this.mydata)
          break
      }

      // 收到服务器信息，心跳重置
      this.reset()
    },
    websocketOnclose: function(e) {
      console.log(
        'websocket 断开: ' + e.code + ' ' + e.reason + ' ' + e.wasClean
      )
      var msg = JSON.stringify({
        cmd: 'out_chatting'
      })

      this.websock.send(msg)
      // 重连
      this.reconnect()
    },

    reconnect() {
      // 重新连接
      var that = this
      if (that.lockReconnect) {
        return false
      }
      that.lockReconnect = true
      // 没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum)
      that.timeoutnum = setTimeout(function() {
        // 新连接
        that.initWebSocket()
        that.lockReconnect = false
      }, 10000)
    },
    onerror(e) {
      console.log('出现错误')
      // 重连
      this.reconnect()
    }
  }
}
</script>
<style scoped>
.chat_box {
  background: #f9f9f9;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chat_title {
  height: 44px;
  width: 100%;
  position: fixed;
  border-bottom: 1px solid rgb(184, 180, 180);
  background: #5ca5ff;
}
.message_box {
  flex: 1;
  margin: 4rem 0 5rem 0;
}
.message_box_user {
  margin: 6% 1%;
  display: flex;
  align-items: flex-end;
}
.chat_msg_left {
  order: 1;
  white-space: normal;
  display: flex;
  justify-content: flex-start;
  width: 57vw;
}
.chat_msg_left p {
  background: #d2e6ff;
  word-break: break-all;
  padding: 0.5rem 1rem;
  border-radius: 1pt 11pt 8pt 20pt;
  border-radius: 20px 20px 20px 0px;
  margin: 0;
  color: #333333;
  font-size: 14px;
}
.chat_msg_right {
  white-space: normal;
  display: flex;
  justify-content: flex-end;
  width: 57vw;
  margin-left: 20vw;
}
.chat_msg_right p {
  background: #d2e6ff;
  word-break: break-all;
  padding: 0.5rem 1rem;
  border-radius: 20px 20px 0px 20px;
  margin: 0;
  color: #333333;
  font-size: 14px;
}
.key_box {
  width: 100%;
  position: fixed;
  bottom: 50px;
  display: flex;
}
.send_box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #eeeeee;
}
.send_icon {
  background: #fff;
  padding-left: 2%;
  font-size: 10vw;
  display: flex;
  align-items: center; /*垂直居中*/
}
.van-button--primary {
  color: #fff;
  background-color: #5ca5ff;
  border: 1px solid #5ca5ff;
}
/deep/ .van-nav-bar__title {
  color: white;
}
/deep/ .van-icon-arrow-left::before {
  color: white;
}
</style>
